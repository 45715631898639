/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import classnames from "classnames";
import { PremiumButton } from "components/ButtonPremium";
import premiumDashboardCard from "assets/images/premium-dashboard-card.svg";
import freeDashboardCard from "assets/images/free-dashboard-card.svg";
import crownGreen from "assets/images/crownGreen.svg";
import Modal from "components/Modal";
import { useOutletContext } from "react-router";
import { IOutletAuth } from "types";
import config from "config";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  desc?: string;
  showPremiumButton?: boolean;
}

interface IModalProps {
  isModalShowing: boolean;
  setIsModalShowing: React.Dispatch<React.SetStateAction<boolean>>;
}

export function PremiumBluredBlock({ className, desc }: IProps) {
  const classes = classnames(
    className,
    "blured-block backdrop-blur-sm absolute w-full h-full top-0 right-0 flex items-start z-[10]"
  );
  return (
    <div className={classes}>
      <PremiumCard desc={desc} />
    </div>
  );
}

export function PremiumCard({ className, desc }: IProps) {
  const authContext = useOutletContext<IOutletAuth>();
  const isAuth = authContext.isAuth;

  const classes = classnames(
    className,
    "flex flex-col w-full rounded-md text-primaryBlue text-xs font-semibold flex flex-grow justify-center items-center align-middle text-center"
  );

  return (
    <>
      <div className={classes}>
        <a
          className="w-full h-full max-w-[240px]"
          href={config.PREMIUM_LINK}
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="dashboardCard"
            src={isAuth ? premiumDashboardCard : freeDashboardCard}
            className="w-full h-full object-contain max-w-[240px] select-none"
          />
        </a>
        <div className="font-medium text-[16px] text-primary my-[10px] leading-[22px]">
          {desc && (
            <>
              {desc}
              <br />
            </>
          )}
          See all data by becoming a<br />{" "}
          <a
            href={config.PREMIUM_LINK}
            target="_blank"
            rel="noreferrer"
            className={classnames(
              { "text-[#28C18A]": !isAuth },
              "text-[#28C18A] hover:underline"
            )}
          >
            premium
          </a>{" "}
          subscriber
        </div>
        <PremiumButton className="mb-4" />
      </div>{" "}
    </>
  );
}

export function PremiumLimitModal({
  isModalShowing,
  setIsModalShowing,
}: IModalProps) {
  return (
    <Modal
      isShowing={isModalShowing}
      showTitle={false}
      showHeader={false}
      customMaxWidth={true}
      classNames={{
        popup: "max-w-[570px]",
      }}
      onHide={() => {
        setIsModalShowing(false);
      }}
    >
      <PremiumCard
        className="pt-8"
        desc="Sorry! Limit of 5 pages to view is over."
      />
    </Modal>
  );
}

export function PremiumTooltip({ className, showPremiumButton }: IProps) {
  const authContext = useOutletContext<IOutletAuth>();
  const isAuth = authContext.isAuth;
  const classes = classnames(
    className,
    "premium-tooltip flex relative left-0 top-[100%] z-30"
  );

  return (
    <>
      <div className="tooltip-wrapper">
        <div className={classes}>
          <div className="p-2 w-[210px] shadow bg-primary rounded-md text-primaryBlue text-xs font-semibold flex flex-col justify-center items-center align-middle text-center">
            <img
              alt="crownGreen"
              src={crownGreen}
              className="w-[25px] h-full object-contain"
            />
            <div className="font-medium text-[14px] text-primary my-2">
              See all data by becoming a<br />{" "}
              <a
                className={classnames(
                  { "text-[#28C18A]": !isAuth },
                  "text-[#28C18A] hover:underline"
                )}
                href={config.PREMIUM_LINK}
                target="_blank"
                rel="noreferrer"
              >
                premium
              </a>{" "}
              subscriber
            </div>
            {showPremiumButton && <PremiumButton />}
          </div>
        </div>
      </div>
    </>
  );
}

export function PremiumLinkWithIcon({ className }: IProps) {
  const classes = classnames(
    className,
    "flex flex-row items-center justify-end"
  );

  return (
    <>
      <div className={classes}>
        <img
          alt="crownGreen"
          src={crownGreen}
          className="w-[10px] mr-1 h-full object-contain"
        />{" "}
        <a
          className="text-[#28C18A] text-[12px] font-bold cursor-pointer"
          href={config.PREMIUM_LINK}
          target="_blank"
          rel="noreferrer"
        >
          Premium
        </a>
      </div>
    </>
  );
}
